export const dictionary = {
    home: {
        balance: {
            ru: 'Ваш баланс',
            en: 'Your balance'
        },
        menu_send: {
            ru: 'Отправить',
            en: 'Send'
        },
        menu_get: {
            ru: 'Получить',
            en: 'Receive'
        },
        token_management: {
            ru: 'Управление токенами',
            en: 'Token management'
        }
    },

    get: {
        address_your_wallet: {
            ru: 'Адрес вашего кошелька',
            en: 'Address of your wallet'
        },
        message_address1: {
            ru: 'Отправляйте только Tether ',
            en: 'Send only Tether '
        },
        message_address2: {
            ru: ` на этот адрес.
            Отправка любых других монет может привести к их безвозвратной потере.`,
            en: ` to this address.
            Sending any other coins may result in their permanent loss.`
        },
        address_copied: {
            ru: 'Адрес скопирован!',
            en: 'Address copied!'
        },
        copy: {
            ru: 'Скопировать',
            en: 'Copy'
        },
        share: {
            ru: 'Поделиться',
            en: 'Share'
        },
        
    },

    send: {
        send: {
            ru: 'Отправить',
            en: 'Send'
        },
        yourbalance: {
            ru: 'Ваш баланс',
            en: 'Your balance'
        }, //!
        amount_adv: {
            ru: 'Сумма объявлений',
            en: 'Amount of advertisements'
        }, //!
        amount_send_comm: {
            ru: 'Сумма вывода / комиссия',
            en: 'Withdrawal amount / commission'
        },
        fill_details: {
            ru: 'Сумма вывода / комиссия',
            en: 'Fill in the details'
        },
        invalid_address: {
            ru: 'Неверный формат адреса',
            en: 'Invalid address format'
        },
        wrong_amount: {
            ru: 'Сумма превышает баланс или меньше коммисии',
            en: 'The amount exceeds the balance or is less than the commission'
        },
        confirm_transfer: {
            ru: 'Подтвердите перевод',
            en: 'Confirm the transfer'
        },
        confirm: {
            ru: 'Подтвердить',
            en: 'Confirm'
        },
        expectation: {
            ru: 'Ожидание..',
            en: 'Expectation..'
        },
        look_at: {
            ru: 'Посмотреть на ',
            en: 'Look at '
        },

        assets: {
            ru: 'Актив',
            en: 'Assets'
        },

        recipient: {
            ru: 'Получатель',
            en: 'Recipient'
        },

        network_fee: {
            ru: 'Сетевой сбор',
            en: 'Network fee'
        },

        total: {
            ru: 'Итого',
            en: 'Total'
        },
    },

    p2p: {
        create_ad: {
            ru: 'Создать объявление',
            en: 'Create an ad'
        }, //!
        support: {
            ru: 'Поддержка',
            en: 'Support'
        },
        market: {
            ru: 'Маркет',
            en: 'Market'
        },
        no_commission: {
            ru: 'Без комиссии',
            en: 'No commission'
        },
        personal_area: {
            ru: 'Личный кабинет',
            en: 'Personal Area'
        }, //!!
        latest_deals: {
            ru: 'Последние сделки',
            en: 'Latest deals'
        },
        purchase: {
            ru: 'Покупка',
            en: 'Purchase'
        }, //!
        sale: {
            ru: 'Продажа',
            en: 'Sale'
        }, //!
        request: {
            ru: 'Запрос',
            en: 'Request'
        },
        waiting_payment: {
            ru: 'Ожидание оплаты',
            en: 'Waiting for payment'
        },
        canceled: {
            ru: 'Отменена',
            en: 'Canceled'
        },
        completed: {
            ru: 'Завершена',
            en: 'Completed'
        },
        

        
        
        
    },

    market: {
        buy: {
            ru: 'Купить',
            en: 'Buy'
        }, //!
        sale: {
            ru: 'Продать',
            en: 'Sale'
        }, //!
        all: {
            ru: 'Все',
            en: 'All'
        },
        qty: {
            ru: 'Кол-во',
            en: 'Qty'
        },
        price_per: {
            ru: 'Цена за',
            en: 'Price per'
        },//!
        transactions: {
            ru: 'сделок',
            en: 'transactions'
        },
        available: {
            ru: 'Доступно',
            en: 'Available'
        }, //!
        limits: {
            ru: 'Лимиты',
            en: 'Limits'
        }, //!
        payment_methods: {
            ru: 'Методы оплаты',
            en: 'Payment methods'
        }, //!
        you_buy: {
            ru: 'Вы покупаете у',
            en: 'You buy from'
        },
        choose: {
            ru: 'Выбрать',
            en: 'Choose'
        },
        message_buy: {
            ru: 'Внимательно прочтите следующие условия мерчанта перед размещением ордера. Несоблюдение условий может привести к неудачным транзакциям и финансовым потерям.',
            en: 'Please read the following merchant terms and conditions carefully before placing an order. Failure to comply with the terms and conditions may result in unsuccessful transactions and financial loss.'
        },
        amount_not_limit: {
            ru: 'Сумма не в лимитах',
            en: 'The amount is not limited'
        },
        amount_exceeds_balance: {
            ru: 'Сумма превышает баланс',
            en: 'Amount exceeds balance'
        }, //!
        add_details: {
            ru: 'Добавить реквизиты',
            en: 'Add details'
        },
        
        add_payment_method: {
            ru: 'Добавьте метод оплаты',
            en: 'Add a payment method'
        },
        
        start_deal: {
            ru: 'Начать сделку',
            en: 'Start a deal'
        },
        
        deal: {
            ru: 'Сделка',
            en: 'Deal'
        },

        you_buying: {
            ru: 'Вы покупаете',
            en: 'You are buying'
        },

        you_sell: {
            ru: 'Вы продаете',
            en: 'Do you sell'
        },

        sells: {
            ru: 'Продает',
            en: 'Sells'
        },

        chat_buyer: {
            ru: 'Чат с покупателем',
            en: 'Chat with the buyer'
        },
        
        chat_seller: {
            ru: 'Чат с продавцом',
            en: 'Chat with the seller'
        },

        attention: {
            ru: 'Внимание',
            en: 'Attention'
        },

        you_must_send: {
            ru: 'Вы должны отправить',
            en: 'You must send'
        },

        within_15_minutes: {
            ru: 'в течение 15 минут',
            en: 'within 15 minutes'
        },
        
        transfer_payment_within: {
            ru: 'Переведите оплату в течение',
            en: 'Transfer payment within'
        },

        confirm_payment: {
            ru: 'Подтвердить платеж',
            en: 'Confirm payment'
        },
        
        status: {
            ru: 'Статус',
            en: 'Status'
        },

        buyer_confirmed_payment: {
            ru: 'Покупатель подтвердил оплату',
            en: 'Buyer confirmed payment'
        },

        make_sure_money: {
            ru: 'Убедитесь что фиаты поступили прежде чем подтверждать оплату',
            en: 'Make sure fiat money has arrived before confirming payment'
        },

        transaction_progress: {
            ru: 'Транзакция выполняется..',
            en: 'Transaction in progress..'
        },

        confirm_pay: {
            ru: 'Подтвердить оплату',
            en: 'Confirm payment'
        },

        deal_canceled: {
            ru: 'Сделка отменена',
            en: 'Deal canceled'
        },

        appeal: {
            ru: 'Апелляция',
            en: 'Appeal'
        },

        you_have_active_appeal: {
            ru: 'У вас есть действующая апелляция',
            en: 'Do you have an active appeal?'
        },

        waiting_confirmation: {
            ru: 'Ожиданием подтверждения',
            en: 'Waiting for confirmation'
        },

        deal_completed: {
            ru: 'Сделка совершена',
            en: 'The deal is completed'
        },

        from_seller: {
            ru: 'от продавца',
            en: 'from the seller'
        },

        waiting_confirm_seller: {
            ru: 'Ожидание подтверждения сделки от продавца',
            en: 'Waiting for transaction confirmation from the seller'
        },
        
        update_status: {
            ru: 'Обновить статус',
            en: 'Update status'
        },

        you_credited: {
            ru: 'Вам начислено',
            en: 'You have been credited'
        },
        
        price: {
            ru: 'Цена',
            en: 'Price'
        },

        sum: {
            ru: 'Сумма',
            en: 'Sum'
        }, //!

        wants_buy_you: {
            ru: 'хочет купить у вас',
            en: 'wants to buy from you'
        },

        buys: {
            ru: 'Покупает',
            en: 'Buys'
        },

        payment: {
            ru: 'оплаты',
            en: 'payment'
        },

        you_sold: {
            ru: 'Вы продали',
            en: 'You sold'
        },

        seller_confirmed: {
            ru: 'Продавец подтвердил зявку',
            en: 'The seller confirmed the request'
        },

        wants_sell: {
            ru: 'хочет продать вам',
            en: 'wants to sell you'
        },

        time_accept: {
            ru: 'Время на принятие запроса',
            en: 'Time to accept request'
        },

        
        refuse: {
            ru: 'Отказаться',
            en: 'Refuse'
        },

        accept_request: {
            ru: 'Принять запрос',
            en: 'Accept request'
        },

        purchase_amount: {
            ru: 'Cумма покупки',
            en: 'Purchase amount'
        },

        from_buyer: {
            ru: 'от покупателя',
            en: 'from the buyer'
        },

        account_card_phone: {
            ru: 'Аккаунт, номер карты или телефон',
            en: 'Account, card number or phone number'
        }, //!
        
        full_name: {
            ru: 'ФИО',
            en: 'Full name'
        }, //!
        
        
    },

    sum: {
        ru: 'Сумма',
        en: 'Sum'
    },

    your_price: {
        ru: 'Ваша цена',
        en: 'Your price'
    },
    
    sale: {
        ru: 'Продать',
        en: 'Sale'
    },

    the_sale: {
        ru: 'Продажа',
        en: 'Sale'
    },

    purchase: {
        ru: 'Покупка',
        en: 'Purchase'
    },

    cryptocurrencies: {
        ru: 'криптовалюты',
        en: 'cryptocurrencies'
    },

    fiat_currency: {
        ru: 'Фиатная валюта',
        en: 'Fiat currency'
    },

    
    floating_price: {
        ru: 'Плавающая цена',
        en: 'Floating price'
    },

    
    market_price: {
        ru: 'Цена на рынке',
        en: 'Market price'
    },

    
    fix_price: {
        ru: 'Фиксированная цена',
        en: 'Fix price'
    },

    yourbalance: {
        ru: 'Ваш баланс',
        en: 'Your balance'
    },

    amount_adv: {
        ru: 'Сумма объявлений',
        en: 'Amount of advertisements'
    },

    max: {
        ru: 'Макс.',
        en: 'Max.'
    },

    minut: {
        ru: 'мин',
        en: 'min'
    },

    hour: {
        ru: 'час',
        en: 'hour'
    },

    hours: {
        ru: 'часов',
        en: 'hours'
    },

    create_ad: {
        ru: 'Создать объявление',
        en: 'Create an ad'
    },

    transaction_limit: {
        ru: 'Лимит сделки',
        en: 'Transaction limit'
    },

    
    pay_within: {
        ru: 'Оплатить в течение',
        en: 'Pay within'
    },

    amount_exceeds_balance: {
        ru: 'Сумма превышает баланс',
        en: 'Amount exceeds balance'
    },

    
    invalid_limit: {
        ru: 'Неверный лимит',
        en: 'Invalid limit'
    },

    
    enter_data: {
        ru: 'Введите данные',
        en: 'Enter data'
    },

    further: {
        ru: 'Далее',
        en: 'Further'
    },

    
    price_type: {
        ru: 'Тип цены',
        en: 'Price type'
    },

    buy: {
        ru: 'Купить',
        en: 'Buy'
    },

    
    min_dot: {
        ru: 'Мин.',
        en: 'Min.'
    },

    
    i_want: {
        ru: 'Я хочу',
        en: 'I want'
    },

    
    name_will_id: {
        ru: 'Это имя будет Вашим ID для всех операций на P2P Маркете.',
        en: 'This name will be your ID for all operations on the P2P Market.'
    },

    
    completed_transactions: {
        ru: 'Завершенные сделки',
        en: 'Completed transactions'
    },

    
    insufficient_balance: {
        ru: 'Недостаточный баланс',
        en: 'Insufficient balance'
    },

    
    ads_deactivated: {
        ru: 'Объявления деактивированы',
        en: 'Ads are deactivated'
    },

    ads_activated: {
        ru: 'Объявления активированы',
        en: 'Ads are activated'
    },

    
    cancel: {
        ru: 'Отмена',
        en: 'Cancel'
    },

    personal_area: {
        ru: 'Личный кабинет',
        en: 'Personal Area'
    },

    
    setting_payment: {
        ru: 'Настройка оплаты',
        en: 'Setting up payment'
    },

    
    activity_ads: {
        ru: 'Активность всех объявлений',
        en: 'Activity of all ads'
    },

    
    your_advertisements: {
        ru: 'Ваши объявления',
        en: 'Your advertisements'
    },

    price_per: {
        ru: 'Цена за',
        en: 'Price per'
    },

    payment_methods: {
        ru: 'Методы оплаты',
        en: 'Payment methods'
    },

    
    edit: {
        ru: 'Редактировать',
        en: 'Edit'
    },

    
    stop: {
        ru: 'Остановить',
        en: 'Stop'
    },

    
    launch: {
        ru: 'Запустить',
        en: 'Launch'
    },

    
    amount_deals: {
        ru: 'Количество сделок',
        en: 'Amount of deals'
    },

    deals: {
        ru: 'сделки',
        en: 'deals'
    },

    available: {
        ru: 'Доступно',
        en: 'Available'
    },

    limits: {
        ru: 'Лимиты',
        en: 'Limits'
    },

    add_payment_methods: {
        ru: 'Добавить методы оплаты',
        en: 'Add payment methods'
    },

    
    add_payments_method: {
        ru: 'Добавить метод оплаты',
        en: 'Add payment method'
    },

    
    choose_payment_method: {
        ru: 'Выберите метод оплаты',
        en: 'Choose your payment method'
    },

    
    a_comment: {
        ru: 'Комментарий',
        en: 'A comment'
    },

    
    payment_method: {
        ru: 'Метод оплаты',
        en: 'Payment method'
    },

    
    merchant_transaction: {
        ru: 'Комиссия продавца за каждую транзакцию',
        en: 'Merchant fee per transaction'
    },

    
    buyer_commission: {
        ru: 'Комиссия покупателя',
        en: 'Buyer commission'
    },

    
    check_ad: {
        ru: 'Проверьте объявление',
        en: 'Check the ad'
    },

    
    advertisement_placed: {
        ru: 'Объявление успешно размещено',
        en: 'The advertisement was successfully placed'
    },

    
    to_market: {
        ru: 'Перейти в маркет',
        en: 'Go to market'
    },

    
    my_announcements: {
        ru: 'Мои объявления',
        en: 'My announcements'
    },

    account_card_phone: {
        ru: 'Аккаунт, номер карты или телефон',
        en: 'Account, card number or phone number'
    },

    save: {
        ru: 'Сохранить',
        en: 'Save'
    },

    delete: {
        ru: 'Удалить',
        en: 'Delete'
    },

    full_name: {
        ru: 'ФИО',
        en: 'Full name'
    },

    
    add: {
        ru: 'Добавить',
        en: 'Add'
    },

    
    card: {
        ru: 'Карта:',
        en: 'Card:'
    },

}